import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Alert,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";

import useAuth from "hooks/useAuth";

import coverImg from "assets/cover2.png";
import logo from "assets/logo.png";

const LoginSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number should be exactly 10 digits")
    .matches(/^[6-9][0-9]{9}$/, "Invalid phone number"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export default function LoginPage() {
  const { login } = useAuth();

  const navigate = useNavigate();

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    useFormik({
      initialValues: {
        phoneNumber: "",
        password: "",
        rememberMe: true,
      },
      validationSchema: LoginSchema,
      onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
        const { phoneNumber, password, rememberMe } = values;
        const { success, message } = await login(
          {
            phoneNumber,
            password,
          },
          rememberMe
        );
        if (!success) {
          resetForm();
          setSubmitting(false);
          setErrors({
            afterSubmit: message,
          });
        }
      },
    });

  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <Stack height="100vh" direction="row">
      <Stack flex={1}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          // justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{
            backgroundImage: `url(${coverImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            overflow: "auto",
          }}
        >
          <Stack
            flex={1}
            px={2}
            py={2}
            flexDirection="column"
            spacing={3}
            justifyContent="center"
            width={{ xs: "100%", sm: "100%", lg: "40%", md: "50%" }}
          >
            <Stack alignItems="center">
              <Box
                component="img"
                src={logo}
                sx={{
                  width: "200px",
                  flexShrink: 0,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: ".1em",
                  }}
                >
                  On Demand Drivers
                </Box>
              </Box>
            </Stack>
            <Stack
              style={{
                background: "rgba(255, 255, 255, 0.19)",
                borderRadius: "20px",
                padding: "20px",
                width: "100%",
                backdropFilter: "blur(6px)",
              }}
            >
              {/* Login component */}
              <Stack justifyContent="center" height="100%">
                <Stack alignItems="center" flex={1}>
                  <Stack flex={1} spacing={2} width="100%">
                    <Stack flex={1} spacing={1} width="100%">
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontFamily: "Inter",
                          fontSize: "30px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "30px",
                          letterSpacing: "-0.72px",
                          textAlign: "center",
                        }}
                      >
                        Login
                      </Typography>
                      <Typography
                        sx={{
                          color: "#FFFFFFB2",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          letterSpacing: "-0.36px",
                          textAlign: "center",
                        }}
                      >
                        Provide your credentials to proceed, please.
                      </Typography>
                      {errors.afterSubmit && (
                        <Alert severity="error">{errors.afterSubmit}</Alert>
                      )}
                    </Stack>

                    <Stack>
                      <Stack
                        sx={{
                          background: "#FFFFFF",
                          borderRadius: "6px",
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#404A4DB2",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "15px",
                          }}
                        >
                          Phone number
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          {...getFieldProps("phoneNumber")}
                          // type="number"
                          error={Boolean(
                            touched.phoneNumber && errors.phoneNumber
                          )}
                          variant="standard"
                          margin="normal"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          sx={{
                            margin: 0,
                          }}
                          onKeyPress={handleKeyPress}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            style: {
                              color: "#131D32",
                            },
                          }}
                        />
                      </Stack>
                      {touched.phoneNumber && errors.phoneNumber && (
                        <Typography
                          variant="caption"
                          align="center"
                          sx={{
                            color: "red",
                          }}
                        >
                          {errors.phoneNumber}
                        </Typography>
                      )}
                    </Stack>
                    <Stack>
                      <Stack
                        sx={{
                          background: "#FFFFFF",
                          borderRadius: "6px",
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#404A4DB2",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "15px",
                          }}
                        >
                          Password
                        </Typography>
                        <TextField
                          fullWidth
                          type="password"
                          size="small"
                          {...getFieldProps("password")}
                          error={Boolean(touched.password && errors.password)}
                          variant="standard"
                          margin="normal"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          sx={{
                            margin: 0,
                          }}
                          inputProps={{
                            style: {
                              color: "#131D32",
                            },
                          }}
                        />
                      </Stack>
                      {touched.password && errors.password && (
                        <Typography
                          variant="caption"
                          align="center"
                          sx={{
                            color: "red",
                          }}
                        >
                          {errors.password}
                        </Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="remember-me"
                              {...getFieldProps("rememberMe")}
                              checked={values.rememberMe}
                              sx={{ color: "#fff" }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                              }}
                              htmlFor="remember-me"
                            >
                              Remember me
                            </Typography>
                          }
                        />
                      </Stack>
                      <Typography
                        sx={{
                          color: "#FFFFFFBF",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/auth/forgot-password")}
                      >
                        Forgot Password?
                      </Typography>
                    </Stack>
                    <Stack>
                      <LoadingButton
                        sx={{
                          display: "flex",
                          padding: "8px 18px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          borderRadius: "8px",
                          background: "#088395",
                          color: "#fff",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          textTransform: "none",
                          ":hover": {
                            bgcolor: "#00a7bf",
                            // color: "black",
                          },
                        }}
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      >
                        Login
                      </LoadingButton>
                    </Stack>
                    <Stack
                      className="sign-up-caption"
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Typography
                        sx={{
                          color: "#838383",
                          fontWeight: 400,
                        }}
                      >
                        Not registered yet?
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/auth/sign-up")}
                      >
                        Create account.
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
