import { Outlet } from "react-router-dom";
import { Stack, Box } from "@mui/material";

import coverImg from "assets/cover2.png";
import logo from "assets/logo.png";

export default function AuthLayout() {
  return (
    <Stack height="100vh" direction="row">
      <Stack flex={1}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          // justifyContent="center"
          alignItems="center"
          flex={1}
          sx={{
            backgroundImage: `url(${coverImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            overflow: "auto",
          }}
        >
          <Stack
            flex={1}
            px={2}
            py={2}
            flexDirection="column"
            spacing={3}
            justifyContent="center"
            width={{ xs: "100%", sm: "100%", lg: "40%", md: "50%" }}
          >
            <Stack alignItems="center">
              <Box
                component="img"
                src={logo}
                sx={{
                  width: "200px",
                  flexShrink: 0,
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    letterSpacing: ".1em",
                  }}
                >
                  On Demand Drivers
                </Box>
              </Box>
            </Stack>
            <Stack
              style={{
                background: "rgba(255, 255, 255, 0.19)",
                borderRadius: "20px",
                padding: "20px",
                width: "100%",
                backdropFilter: "blur(6px)",
              }}
            >
              <Outlet />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
