import { Outlet } from "react-router-dom";
import { Stack, useMediaQuery } from "@mui/material";

import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";

export default function AppLayout() {
  const hidden = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Stack height="100vh">
      <Stack
        sx={{
          height: "80px",
          ...(!hidden && { height: "60px" }),
        }}
      >
        <Navbar />
      </Stack>
      <Stack flex={1} sx={{ overflow: "auto" }}>
        <Outlet />
        <Footer />
      </Stack>
    </Stack>
  );
}
