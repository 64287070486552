import { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

import LoadingScreen from "components/LoadingScreen";
import AuthLayout from "layouts/AuthLayout2";
import AppLayout from "layouts/AppLayout";
import AuthGuard from "components/AuthGuard";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/app/profile" replace />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <Login />,
        },
        { path: "sign-up", element: <SignUp /> },
        { path: "forgot-password", element: <ForgotPassword /> },
      ],
    },
    {
      path: "app",
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/app/home" replace />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "delete-account",
          element: <DeleteAccount />,
        },
        {
          path: "trips",
          element: <Trips />,
        },
        {
          path: "trip/:bookingId",
          element: <TripDetails />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "booking",
          element: <Booking />,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/app/home" replace />,
    },
  ]);
}

const PrivacyPolicy = Loadable(
  lazy(() => import("../components/PrivacyPolicy"))
);
const Login = Loadable(lazy(() => import("../components/login/Login2")));
const SignUp = Loadable(lazy(() => import("../components/signUp/SignUp2")));
const ForgotPassword = Loadable(
  lazy(() => import("../components/forgotPassword/ForgotPassword2"))
);

const DeleteAccount = Loadable(
  lazy(() => import("../components/DeleteAccount"))
);
const Home = Loadable(lazy(() => import("../components/home/Home")));
const Trips = Loadable(lazy(() => import("../components/trips/Trips")));
const TripDetails = Loadable(
  lazy(() => import("../components/trips/TripDetails"))
);
const AboutUs = Loadable(lazy(() => import("../components/aboutUs/AboutUs")));
const Profile = Loadable(lazy(() => import("../components/profile/Profile")));
const Booking = Loadable(lazy(() => import("../components/bookings/Booking")));
