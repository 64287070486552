import LoginPage from "components/login/LoginPageAdmin2";
import useAuth from "hooks/useAuth";

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return <>{children}</>;
}
