import { Stack, Typography, useMediaQuery } from "@mui/material";

export default function Footer() {
  const currentYr = new Date().getFullYear();
  const hidden = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Stack
      sx={{
        background: "#001a24",
      }}
    >
      {/* <Stack px={8} py={5}></Stack> */}
      <Stack
        sx={{
          background: "rgba(255, 255, 255, 0.19)",
        }}
        px={hidden ? 8 : 1}
        py={1}
      >
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.75)",
            textAlign: "left",
            fontSize: "10px",
            fontWeight: 400,
          }}
        >
          © Copyright {currentYr} - All rights Reserved - On Demand Drivers
        </Typography>
      </Stack>
    </Stack>
  );
}
