import Box from "@mui/material/Box";

import coverImg from "../assets/cover.png";
import logo from "../assets/logo.png";

export default function LoadingScreen() {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: `url(${coverImg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Box
        component="img"
        src={logo}
        sx={{
          width: { xs: "150px", lg: "300px" },
          flexShrink: 0,
        }}
      />
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            color: "#FFF",
            textShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
            fontFamily: "Inter",
            fontSize: { xs: "2rem", lg: "3rem" },
            fontStyle: "normal",
            fontWeight: 400,
            // letterSpacing: "0.2px",
            textAlign: "center",
          }}
        >
          Welcome to
        </Box>
        <Box
          sx={{
            color: "#FFF",
            fontFamily: "Inter",
            fontSize: { xs: "2rem", lg: "4rem" },
            fontStyle: "normal",
            fontWeight: "500",
            // letterSpacing: "0.2px",
            textAlign: "center",
          }}
        >
          On Demand Drivers
        </Box>
      </Box>
    </Box>
  );
}
