import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { matchPath } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Icon } from "@iconify/react";

import useAuth from "hooks/useAuth";

import logo from "assets/logo2.png";
import { ReactComponent as DownArrow } from "assets/down-arrow.svg";
import { ReactComponent as MenuIcon } from "assets/svg-icons/menu-icons.svg";
import callIcon from "assets/svg/call-icon.svg";

import "./navbar.css";
import MenuPopover from "components/MenuPopover";

const links = [
  {
    name: "Home",
    link: "/app/home",
  },
  {
    name: "Trips",
    link: "/app/trips",
  },

  {
    name: "About Us",
    link: "/app/about-us",
  },
];

function makeCall() {
  window.location.href = "tel:+916282621345";
}

export default function Navbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user, logout } = useAuth();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    logout();
  };

  const handleDeleteAccount = () => {
    navigate("/app/delete-account");
    handleClose();
  };

  const hidden = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  return (
    <Stack
      sx={{
        height: "70px",
        padding: "0 50px",
        justifyContent: "space-between",
        flexShrink: 0,
        background: "#fff",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
        zIndex: 99,

        ...(!hidden && { padding: "0 10px", height: "50px" }),
      }}
    >
      <Stack direction="row" alignItems="center" height="100%">
        {hidden && (
          <Box
            component="img"
            src={logo}
            sx={{
              width: 70,
              ...(!hidden && { width: 50 }),
              cursor: "pointer",
            }}
            onClick={() => navigate("/app/home")}
          />
        )}
        {hidden && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            height="100%"
            ml={10}
          >
            {links.map((l) => (
              <Stack
                className="menu-item"
                key={l.name}
                height="100%"
                justifyContent="center"
                width="130px"
                sx={{
                  transform: "skew(20deg)",
                  ...(l?.link &&
                    !!matchPath({ path: l?.link, end: true }, pathname) && {
                      backgroundColor: "#0a4d68",
                      color: "#fff",
                    }),
                }}
                onClick={() => navigate(l?.link)}
              >
                <Typography
                  sx={{
                    transform: "skew(-20deg)",
                  }}
                >
                  {l.name}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {hidden && (
          <Stack ml="auto" direction="row" spacing="20px" alignItems="center">
            <Avatar
              sx={{
                textTransform: "capitalize",
                background: "rgb(8, 131, 149)",
                cursor: "pointer",
              }}
              onClick={() => navigate("/app/profile")}
            >
              {user.fullName?.[0]}
            </Avatar>
            <Stack>
              <Typography
                sx={{
                  color: "#555",
                  fontFamily: "Inter",
                  fontSize: "10px",
                  fontWeight: 600,
                }}
              >
                Welcome,
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {user?.fullName}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: "pointer" }}
              onClick={handleOpen}
              ref={anchorRef}
            >
              <DownArrow stroke="rgba(34, 34, 34, 1)" />
            </Stack>
            <MenuPopover
              open={open}
              onClose={handleClose}
              anchorEl={anchorRef.current}
              sx={{ width: 220 }}
            >
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography
                  sx={{
                    color: "#222",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                  variant="subtitle1"
                  noWrap
                >
                  {user.fullName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#222",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                  noWrap
                >
                  {user?.email}
                </Typography>
              </Box>

              <Divider sx={{ my: 1 }} />
              {/* <Box sx={{ p: 2, pt: 1.5 }}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={handleDeleteAccount}
                >
                  Delete Account
                </Button>
              </Box>

              <Divider sx={{ my: 1 }} /> */}

              <Box sx={{ p: 2, pt: 1.5 }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Box>
            </MenuPopover>
          </Stack>
        )}
        {!hidden && (
          <Stack flexDirection="row" justifyContent="space-between" flex={1}>
            <Stack flex={1} justifyContent="center" alignItems="start">
              <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
                {/* <Icon
                  icon="material-symbols:menu"
                  style={{ fontSize: "20px" }}
                /> */}
                <MenuIcon />
              </IconButton>

              <Drawer anchor="left" open={drawer} onClose={toggleDrawer(false)}>
                <Stack
                  sx={{
                    width: 200,
                    flex: 1,
                  }}
                  role="presentation"
                  onKeyDown={toggleDrawer(false)}
                >
                  <Stack
                    sx={{ height: "50px" }}
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection="row"
                    px={1}
                  >
                    <Stack
                      flexDirection="row"
                      justifyContent="center"
                      spacing={1}
                      sx={{ gap: "5px" }}
                    >
                      <Avatar
                        sx={{
                          textTransform: "capitalize",
                          background: "rgb(8, 131, 149)",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/app/profile")}
                      >
                        {user.fullName?.[0]}
                      </Avatar>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#222",
                            fontFamily: "Inter",
                            fontSize: "15px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                          variant="subtitle1"
                          noWrap
                        >
                          {user.fullName}
                        </Typography>
                        {/* <Typography
                        variant="body2"
                        sx={{
                          color: "#222",
                          fontFamily: "Inter",
                          fontSize: "15px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                        noWrap
                      >
                        {user?.email}
                      </Typography> */}
                      </Stack>
                    </Stack>

                    <IconButton aria-label="menu" onClick={toggleDrawer(false)}>
                      <Icon
                        icon="material-symbols:close"
                        style={{ fontSize: "20px" }}
                        color="rgb(255, 31, 31)"
                      />
                    </IconButton>
                  </Stack>
                  <Divider />

                  <Divider />
                  <Stack alignItems="center" spacing={1}>
                    {links.map((l) => (
                      <Stack
                        key={l.name}
                        height="50px"
                        justifyContent="center"
                        alignItemss="center"
                        width="100%"
                        px={3}
                        sx={{
                          ...(l?.link &&
                            !!matchPath(
                              { path: l?.link, end: true },
                              pathname
                            ) && {
                              backgroundColor: "#0a4d68",
                              color: "#fff",
                            }),
                        }}
                        onClick={() => navigate(l?.link)}
                      >
                        <Typography>{l.name}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                  <Divider />
                  <Stack mt="auto">
                    {/* <Box sx={{ p: 1 }}>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={handleDeleteAccount}
                      >
                        Delete Account
                      </Button>
                    </Box>

                    <Divider sx={{ my: 1 }} /> */}

                    <Box sx={{ p: 1 }}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="outlined"
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </Box>
                  </Stack>
                </Stack>
              </Drawer>
            </Stack>
            <Stack flex={1} justifyContent="center" alignItems="center">
              <Box
                component="img"
                src={logo}
                sx={{
                  width: 60,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/app/home")}
              />
            </Stack>
            <Stack justifyContent="center" alignItems="end" flex={1}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  display: "flex",
                  gap: 1,
                  borderRadius: "50px",
                  px: 2,
                  background: "#088395",
                }}
                onClick={makeCall}
              >
                <Box component="img" src={callIcon} />
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  Call Now
                </Typography>
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
