import { createContext, useEffect, useReducer } from "react";

import { axiosApp } from "../utils/appAxios";
import { setItem, getItem, removeItem } from "../utils/storageManger";
import { TOKEN_NAME } from "../utils/constants";
import { loginRequest, getUserDetails } from "../_api_/auth";

const initialState = {
  isAuthenticated: false,
  loading: true,
  user: null,
  token: null,
  error: null,
};

const TYPES = {
  INITIATE: "INITIATE",
  LOADING: "LOADING",
  ERROR: "ERROR",
  LOGOUT: "LOGOUT",
};

export const JWTReducer = (state, action) => {
  switch (action.type) {
    case TYPES.INITIATE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        error: null,
      };
    case TYPES.LOADING:
      return {
        loading: true,
        error: null,
      };
    case TYPES.ERROR:
      return {
        isAuthenticated: false,
        user: null,
        token: null,
        error: action.payload.error,
        loading: false,
      };
    case TYPES.LOGOUT:
      return {
        error: null,
        isAuthenticated: false,
        user: null,
        token: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const initialize = async (accessToken = null) => {
    dispatch({
      type: TYPES.LOADING,
    });

    const token = accessToken ?? (await getItem(TOKEN_NAME));

    if (token) {
      const { success, data } = await getUserDetails(token);

      if (success) {
        axiosApp.defaults.headers.common.Authorization = token;

        dispatch({
          type: TYPES.INITIATE,
          payload: {
            isAuthenticated: true,
            user: data,
            token,
          },
        });
      } else {
        removeItem(TOKEN_NAME);

        dispatch({
          type: TYPES.INITIATE,
          payload: {
            isAuthenticated: false,
            user: null,
            token: null,
          },
        });
      }
    } else {
      dispatch({
        type: TYPES.INITIATE,
        payload: {
          isAuthenticated: false,
          user: null,
          token: null,
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async (payload, rememberMe = false) => {
    removeItem(TOKEN_NAME);

    const { success, data, message } = await loginRequest(payload);

    if (success) {
      rememberMe && setItem(TOKEN_NAME, data?.accessToken);
      initialize(data?.accessToken);
    } else {
      dispatch({
        type: TYPES.ERROR,
        payload: {
          error: message,
        },
      });
    }
    return { success, data, message };
  };

  const loginWithOTP = async (accessToken, rememberMe = false) => {
    removeItem(TOKEN_NAME);

    rememberMe && setItem(TOKEN_NAME, accessToken);
    initialize(accessToken);

    return {};
  };

  const logout = async () => {
    removeItem(TOKEN_NAME);
    dispatch({ type: TYPES.LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        initialize,
        login,
        logout,
        loginWithOTP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
